@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/rebond/Rebond-Extrabold.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/rebond/Rebond-Bold.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/rebond/Rebond-Semibold.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/rebond/Rebond-Medium.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/rebond/Rebond-Regular.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/rebond/Rebond-Thin.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/rebond/Rebond-Light.ttf");
}
@font-face {
  font-family: "Rebond";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/rebond/Rebond-Hairline.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: italic;
  font-weight: 800;
  src: url("./assets/fonts/rebond/Rebond-ExtraboldItalic.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/rebond/Rebond-MediumItalic.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/rebond/Rebond-ThinItalic.ttf");
}

@font-face {
  font-family: "Rebond";
  font-style: italic;
  font-weight: 200;
  src: url("./assets/fonts/rebond/Rebond-LightItalic.ttf");
}
@font-face {
  font-family: "Rebond";
  font-style: italic;
  font-weight: 100;
  src: url("./assets/fonts/rebond/Rebond-HairlineItalic.ttf");
}

body {
  margin: 0;
  font-family: "Rebond", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
